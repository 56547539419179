import { Button, ButtonVariant, TextInput } from 'brass-ui-kit';
import { Formik } from 'formik';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { resetPasswordPagePath } from 'src/data/pageRoutes';
import { useStoreDispatch } from 'src/redux/hooks';
import If from 'src/sharedComponents/If';
import { TFunc } from 'src/types';
import Navbar from '../../components/Navbar';
import PasswordInput from '../../components/PasswordInput';
import { login } from '../../redux';
import { RDTUserLogin } from '../../types';
import { loginValidationSchema } from '../../validators';

import styles from './Login.module.scss';

interface LoginPageProps {
  customHandleLogin?: TFunc<RDTUserLogin>;
  asEmbed?: boolean;
}

const Login: FC<LoginPageProps> = ({ asEmbed, customHandleLogin }) => {
  const initialValues = {
    email: '',
    password: '',
  };

  const dispatch = useStoreDispatch();

  const handleLogin = async (values: typeof initialValues) => {
    if (customHandleLogin) await customHandleLogin(values);
    else await dispatch(login(values));
  };

  let enableLoginBtn: boolean;
  return (
    <div className={styles.login}>
      <If condition={!asEmbed}>
        <Navbar>
          <></>
          {/* <Button
            label='Open account'
            to={signupPagePath}
            as={NavLink as ComponentType<ButtonProps>}
            variant={ButtonVariant.TextDark}
          /> */}
        </Navbar>
      </If>
      <div className={styles.login_content}>
        <Formik
          validateOnMount
          validateOnChange
          enableReinitialize
          onSubmit={handleLogin}
          initialValues={initialValues}
          validationSchema={loginValidationSchema}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            isSubmitting,
            isValid,
            dirty,
            errors,
            touched,
            handleBlur,
          }) => {
            enableLoginBtn = !(isValid && dirty) || isSubmitting;
            return (
              <form className={styles.login_form} onSubmit={handleSubmit}>
                <h1 className={styles.login_formTitle}>
                  Log in to your account
                </h1>
                <TextInput
                  className={styles.login_formField}
                  label='Email address'
                  name='email'
                  onChange={handleChange}
                  type='email'
                  autoComplete='email'
                  value={values.email}
                  errorMessage={touched.email ? errors?.email : undefined}
                  onBlur={handleBlur}
                />
                <PasswordInput
                  label='Password'
                  className={styles.login_formField}
                  name='password'
                  value={values.password}
                  onChange={handleChange}
                  autoComplete='new-password'
                  errorMessage={touched.password ? errors.password : undefined}
                  onBlur={handleBlur}
                />
                <Button
                  block
                  className={styles.login_formBtn}
                  variant={ButtonVariant.Primary}
                  type='submit'
                  loading={isSubmitting}
                  disabled={enableLoginBtn}
                >
                  Login
                </Button>
                <If condition={!asEmbed}>
                  <p className={styles.login_formFooter}>
                    Forgot your password?
                    <NavLink to={resetPasswordPagePath}>Reset it here</NavLink>
                  </p>
                </If>
              </form>
            );
          }}
        </Formik>
        {/* <p className={styles.login_contentFooter}>
          Don't have an account?
          <NavLink to={signupPagePath}>Open account</NavLink>
        </p> */}
      </div>
    </div>
  );
};

Login.defaultProps = {
  asEmbed: false,
};

export default Login;
